import React from "react";
import { Parallax } from "react-parallax";
//import { useContext } from "react";
import Layout from "../Layout";
import PubsQueima from "../PubsQueima";
import ProjectsIntro from "../ProjectsIntro";
//import { MouseContext } from "../Context/mouse-context";
import queimaLogos from "../videos/logos.gif";
import setBodyColor from "../setBodyColor";

const QueimaDasFitas = () => {
  const banner = "imgs/queimaDasFitas21/banner.png";
  //const { cursorChangeHandler } = useContext(MouseContext);

  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="queimadasfitas">
        <div className="queimadasfitas-intro">
          <ProjectsIntro
            title="QF'21 vol.1"
            type="Professional"
            teamTitle="Team"
            team="Ana Catarina Ricardo Sacadura"
            date="Oct. 2021"
            description="Queima das Fitas is the biggest academic party in 
          all Europe and takes place in May over 8 days, as many as there 
          are Colleges at the University of Coimbra. For us, it was important 
          to create an image that relates to the academic tradition. Designing 
          a grid system was vital to keep consistency a harmony troughout the 
          identity. We wanted to represent a set of different emotions. "
          />
        </div>
        <div className="queimadasfitas-project-1">
          <img src={queimaLogos} alt="logos" className="logos"></img>

          <img
            src="imgs/queimaDasFitas21/Vol.svg"
            className="queimadasfitas-project-1-vol"
            alt="textvolume"
          />

          <img
            src="imgs/queimaDasFitas21/s_1.svg"
            className="simbolo-1"
            alt="s_1"
          />
          <img
            src="imgs/queimaDasFitas21/s_2.svg"
            className="simbolo-2"
            alt="s_2"
          />
          <img
            src="imgs/queimaDasFitas21/s_3.svg"
            className="simbolo-3"
            alt="s_3"
          />
        </div>
        <div className="queimadasfitas-project-2">
          <Parallax bgImage={banner} strength={100}>
            <div className="parallax"></div>
          </Parallax>

          <div className="logos-wrapper">
            <img
              src="imgs/queimaDasFitas21/logo__PRINCIPAL.svg"
              className="logo"
              alt="logo_principal"
            />
            <img
              src="imgs/queimaDasFitas21/logo__DESPORTO.svg"
              className="logo"
              alt="logo_desporto"
            />
            <img
              src="imgs/queimaDasFitas21/logo__DIREITO.svg"
              className="logo"
              alt="logo_direito"
            />
            <img
              src="imgs/queimaDasFitas21/logo__ECONOMIA.svg"
              className="logo"
              alt="logo_economia"
            />
            <img
              src="imgs/queimaDasFitas21/logo__FARMACIA.svg"
              className="logo"
              alt="logo_farmacia"
            />
            <img
              src="imgs/queimaDasFitas21/logo__CIENCIAS E TECNOLOGIA.svg"
              className="logo"
              alt="logo_ct"
            />
            <img
              src="imgs/queimaDasFitas21/logo__LETRAS.svg"
              className="logo"
              alt="logo_letras"
            />
            <img
              src="imgs/queimaDasFitas21/logo__MEDICINA.svg"
              className="logo"
              alt="logo_medicina"
            />
            <img
              src="imgs/queimaDasFitas21/logo__PSICOLOGIA.svg"
              className="logo"
              alt="logo_psicologia"
            />
          </div>
        </div>

        <img
          src="imgs/queimaDasFitas21/simbols.svg"
          className="simbols"
          alt="simbols"
        />

        <img src="imgs/queimaDasFitas21/grid.svg" className="grid" alt="grid" />

        <div className="queimadasfitas-project-3">
          <PubsQueima />
        </div>
      </div>
    </Layout>
  );
};

export default QueimaDasFitas;
