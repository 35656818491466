import React, { useContext } from "react";
import styled from "styled-components";
import setBodyColor from "../../setBodyColor";
import NewNavbar from "../../Navbar/NewNavbar";
import Avatar from "../../Avatar";
import ProjectSection from "../../ProjectSection";
import ImageCard from "../../ImageCard";
import CardData from "../../CardData";
import { MouseContext } from "../../Context/mouse-context";

const Button = styled.a`
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(214, 214, 214, 0.8);
  height: 2.5rem;
  font-family: "HelveticaNow-Medium";
  font-size: 0.875rem;
  padding: 0 0.25rem 0 1.5rem;
  border-radius: 100rem;
  cursor: pointer;
  transition: ease background-color 250ms;
  border: 0;
  outline: 0;
  z-index: 5;
  width: fit-content;
  backdrop-filter: blur(8px);
  &:hover {
    background-color: #f8f8f8;
    color: #111111;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const Thesis = () => {
  const { cursorChangeHandler } = useContext(MouseContext);
  setBodyColor({ color: "#f8f8f8" });

  return (
    <div>
      <NewNavbar />
      <Button
        onMouseEnter={() => cursorChangeHandler("hovered-social")}
        onMouseLeave={() => cursorChangeHandler("")}
        className="paper-btn"
        href="https://drive.google.com/file/d/1ep-SyTY4LjFS92lFcSzbMaNSCAWKUUEZ/view?usp=sharing"
        rel="noreferrer"
        target="_blank"
      >
        View paper
        <span className="material-icons material-symbols-out btn-icon">
          file_open
        </span>
      </Button>
      <div className="mobile-content">
        <div className="loader">
          Mobile magic is coming soon! <br />
          For now, grab your laptop or desktop for the full feast. <br />
          <br />
          Thanks for your patience!
        </div>
      </div>
      <div className="project-page">
        <div className="project-page__content">
          <div className="header">
            <div className="header__content">
              <div className="header__top">
                <div className="header__role">
                  <span>Lead Researcher and Designer</span>
                  <span>•</span>
                  <span>2022-2023</span>
                </div>
                <div className="tags-container tags-container--project">
                  <div className="tag">AI</div>
                  <div className="tag">UX</div>
                  <div className="tag">3D RECONSTRUCTION</div>
                </div>
              </div>
              <div className="header__title">
                <span>
                  Impact of 3D image reconstruction on user experience in a
                  circular economy context
                </span>
              </div>
            </div>

            <div className="header__teams">
              <Avatar
                title="Universidade de Coimbra"
                image="imgs/Avatars/FCTUC-02.webp"
                link="https://www.uc.pt/fctuc/"
              />
              <Avatar
                title="The Loop Co."
                image="imgs/Avatars/TheLoop.webp"
                link="https://www.theloop.pt/"
              />
            </div>
          </div>

          <video
            controls={false}
            autoPlay
            loop
            muted
            src="imgs/thesis/Video-1.mp4"
            type="video/mp4"
            className="project-page__banner"
          />
        </div>

        <div className="project-content">
          <div className="project-content__intro">
            The study explored BabyLoop — an existent digital platform dedicated
            to circulating second-hand childcare products. Beyond the technical
            development and integration of 3D image reconstruction methodologies
            into BabyLoop’s platforms, the study endeavors to discern their
            impact on user experience and decision-making. <br />
            <br />
            The 3D image reconstructions enhanced users’ perception of
            utilitarian value, and the enrichment of product knowledge favorably
            influenced purchase intentions. Such findings demonstrated the
            prospective relevance and potential applicability of this technology
            in refining the digital retail experience, particularly in the realm
            of the circular economy.
          </div>
          {/*-------------- CHALLENGE----------------------------*/}
          <ProjectSection
            title="The Challenge"
            intro="The biggest challenge was to ensure performance and scalability
            since we will be working with second-hand products, which means that
            each model is unique, and so we have to ensure that as products grow
            it will not become a problem."
            subtitleLeft="Problem"
            textLeft="Traditional e-commerce methods, such as presenting products
                through images, may not be effective in providing a clear
                understanding of a product’s features and potential use, leading
                to low user engagement and potentially incorrect purchase
                decisions."
            subtitleRight="Goals"
            textRight={`• Review and analysis of 3D reconstruction solutions;\n
    • Implementation of the 3D reconstruction process;\n
    • Implementation of the 3D model visualization in BabyLoop's e-commerce;\n
    • Automation of the process;\n
    • Evaluate the impact of the models;\n
    • Analyze the results obtained;\n
    • Analyze the implications for the circular economy and second-hand e-commerce.`}
          />
          {/*-------------- ----------------------------*/}
          {/*-------------- DISCOVERY ----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5rem",
            }}
          >
            <ProjectSection
              title="Discovery"
              subtitleLeft="3D reconstruction technologies"
              textLeft="Given a comprehensive assessment of available technologies
                    (Polycam, LumaAI, Nerfstudio, and instant-ngp) and their
                    capabilities, NeRF emerged as the most aligned with our
                    research goals."
              subtitleRight="Why Instant Neural Graphics Primitives (Instant-NGP)?"
              textRight={`• Open-source;\n
    • Ability to automate;\n
    • Active community.`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__paragraph">
                Qualitative comparison of the reconstruction of applications
                under the same conditions
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                <ImageCard
                  title="2D image"
                  image="imgs/thesis/real_plant.webp"
                />
                <ImageCard
                  title="Photogrammetry"
                  image="imgs/thesis/POLYCAM.webp"
                  tags={["POLYCAM"]}
                />
                <ImageCard
                  title="Neural Radiance Fields (NeRF)"
                  image="imgs/thesis/LUMA.webp"
                  tags={["LUMA AI"]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                <ImageCard
                  title="2D image"
                  image="imgs/thesis/binnary_1.webp"
                />
                <ImageCard
                  title="Neural Radiance Fields (NeRF)"
                  image="imgs/thesis/binnary_2.webp"
                  tags={["INSTANT-NGP"]}
                />
              </div>
            </div>
          </div>

          {/*-------------- ----------------------------*/}
          {/*-------------- Instant-NGP----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5rem",
              padding: "0 1rem",
            }}
          >
            <ProjectSection
              title="Instant-NGP process"
              intro="The pursuit of a seamless 3D reconstruction process is essential, particularly in the digital age. Even though a full-fledged automated process for reconstructing 3D models from images was yet to be adopted, its integration into the BabyLoop digital ecosystem was imperative to ensure the future scalability and broad applicability of the solution."
            />
            <img
              src="imgs/thesis/flow.png"
              alt="project_image"
              style={{ background: "none" }}
              className="project-card__image"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__subtitle">Tests</div>
              <div className="project-section__paragraph">
                This exploration unveiled the capabilities of 3D reconstruction
                using video recordings, bridging the gap between professional
                equipment and smartphones. The prowess displayed in
                reconstructing intricate materials, particularly transparent
                ones, underscores the technology’s potential.
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__paragraph">
                Comparison of the outputs of different devices
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                <ImageCard
                  title="Professional camera"
                  image="imgs/thesis/img-1-1.webp"
                />
                <ImageCard title="iPhone 13" image="imgs/thesis/img-1-2.webp" />
                <ImageCard
                  title="iPhone 14 Pro"
                  image="imgs/thesis/img-1-3.webp"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__paragraph">
                Camera positions improvement process
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                <ImageCard title="Initial" image="imgs/thesis/img-2-1.webp" />
                <ImageCard title="Final" image="imgs/thesis/img-2-2.webp" />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__paragraph">
                Comparison from success test using iPhone 13 FullHD 60fps video
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                <ImageCard title="2D image" image="imgs/thesis/img-3-1.webp" />
                <ImageCard
                  title="NeRF 3D model"
                  image="imgs/thesis/img-3-2.webp"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__paragraph">
                Comparison from plastic covering output
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                }}
              >
                <ImageCard title="2D image" image="imgs/thesis/img-4-1.webp" />
                <ImageCard
                  title="NeRF 3D model"
                  image="imgs/thesis/img-4-2.webp"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.5rem",
              }}
            >
              <div
                style={{ padding: "0 10%" }}
                className="project-section__subtitle"
              >
                Final 3D models
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "27rem",
                  overflow: "hidden",
                }}
              >
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/thesis/carrobranco.mp4"
                  type="video/mp4"
                  className="thesis-card__video"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "27rem",
                  overflow: "hidden",
                }}
              >
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/thesis/cesto.mp4"
                  type="video/mp4"
                  className="thesis-card__video"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "27rem",
                  overflow: "hidden",
                  background: "white",
                }}
              >
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/thesis/360-4-02.mov"
                  type="video/mp4"
                  className="thesis-card__video"
                  style={{ width: "50%" }}
                />
              </div>
            </div>
          </div>
          {/*-------------- ----------------------------*/}
          {/*-------------- User experience----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5rem",
            }}
          >
            <ProjectSection
              title="Enhancing User Interaction and Experience"
              intro={
                <>
                  The transition is marked by a careful and deliberate design
                  evolution, both in the back-office and e-commerce.
                  <br />
                  <br />
                  Lighter shades were embraced, giving the interface a fresh,
                  airy feel. This nuanced modification not only emphasizes
                  essential data but also enhances overall legibility.
                  <br />
                  <br />
                  The transformation in the layout is most noticeable on the
                  stock product details page. The content was thoughtfully
                  reorganized into distinct sections, separating descriptive
                  information from price details.
                </>
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1.5rem",
                padding: "0 1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  justifyContent: "center",
                }}
              >
                <div
                  className="project-section__gray-text"
                  style={{ textAlign: "center" }}
                >
                  Old Back Office
                </div>
                <img
                  src="imgs/thesis/old-stock-details.webp"
                  alt="project_image"
                  className="project-card__image"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  justifyContent: "center",
                }}
              >
                <div
                  className="project-section__gray-text"
                  style={{ textAlign: "center" }}
                >
                  New Back Office
                </div>
                <img
                  src="imgs/thesis/new-stock-details.webp"
                  alt="project_image"
                  className="project-card__image"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
                justifyContent: "center",
                padding: "0 1rem",
              }}
            >
              <div
                className="project-section__gray-text"
                style={{ textAlign: "center" }}
              >
                BabyLoop e-commerce 360º product view
              </div>
              <video
                controls={false}
                autoPlay
                loop
                muted
                src="imgs/thesis/shoppify_record.mov"
                type="video/mp4"
                className="project-card__video"
              />
            </div>
          </div>
          {/*-------------- ----------------------------*/}
          {/*-------------- RESULTS ----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5rem",
            }}
          >
            <ProjectSection
              title="Results"
              intro={
                <>
                  Whilst pragmatic, this approach might not fully capture the
                  nuances of diverse real-world contexts or cater to the varied
                  demographics of potential online shoppers.
                  <br />
                  <br />
                  There may also be valid concerns about the adequacy of the
                  number of participants. Even though the sample size was
                  compared with reference studies, the inclusion of only 14
                  participants in the experimental group could pose challenges
                  in deriving strong statistical conclusions.
                </>
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5rem",
                padding: "0 10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5rem",
                }}
              >
                <CardData
                  title="PURCHASE INTENTION"
                  number="16.2%"
                  description="There was a statistically significant increase in purchase intention among users who experienced the 3D product visualization."
                  icon="arrow_upward_alt"
                />
                <CardData
                  title="IMMERSION"
                  number="23.7%"
                  description="The experimental group reported higher immersion scores."
                  icon="arrow_upward_alt"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5rem",
                }}
              >
                <CardData
                  title="PRODUCT KNOWLEDGE"
                  number="12%"
                  description="The experimental group demonstrated a better understanding of product features."
                  icon="arrow_upward_alt"
                />
                <CardData
                  title="UTILITARIAN VALUE"
                  number="11.5%"
                  description="The experimental group reported higher immersion scores."
                  icon="arrow_upward_alt"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="project-section__subtitle">
                {" "}
                Circular Economy Impact
              </div>
              <div className="project-section__paragraph">
                Upon the analysis, several compelling relationships have
                emerged, particularly highlighting the paramount roles of
                utilitarian value, 3D quality, and product knowledge in
                influencing sustainable consumption behaviors within second-hand
                e-commerce. Although derived from a limited sample, they provide
                a robust foundation for future endeavors in this domain.
              </div>
            </div>
          </div>
          {/*-------------- ----------------------------*/}
          {/*-------------- Conclusions----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <ProjectSection
              title="Conclusions"
              intro="These results demonstrate the potential for 3D technology to transform online shopping for second-hand goods, making it a more viable and attractive option for consumers."
            />
            <div
              style={{
                padding: "0 10%",
              }}
              className="project-section__paragraph"
            >
              Given BabyLoop’s eminent stature in the Portuguese marketplace,
              marked by the circulation of over 1,500 products and an exhaustive
              reconditioning methodology, it’s vital to underscore the brand’s
              inherent trustworthiness. Thus, is plausible to anticipate even
              more pronounced impacts in a real scenario, given its established
              practice of equipping users with pioneering informational tools
              throughout their purchasing journey.
            </div>
          </div>
          {/*-------------- ----------------------------*/}
        </div>
        <div className="new-footer">© 2024, NOT FOUND.</div>
      </div>
    </div>
  );
};

export default Thesis;
