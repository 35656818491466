import React from "react";

const ImageCard = ({ title, image, tags }) => {
  return (
    <div className="project-card">
      <div className="tags-container">
        {tags &&
          tags.map((tag, index) => (
            <div key={index} className="tag">
              {tag}
            </div>
          ))}
      </div>
      <div className="project-card__content-container">
        <img src={image} alt="project_image" className="project-card__image" />
      </div>
      <p className="project-section__gray-text" style={{ textAlign: "center" }}>
        {title}
      </p>
    </div>
  );
};

export default ImageCard;
