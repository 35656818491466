import React from "react";
import qf11 from "./videos/qf_11.mp4";

const PubsQueima = () => {
  return (
    <div className="pubs-wrapper">
      <img
        src="imgs/queimaDasFitas21/01.webp"
        alt="01"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/02.webp"
        alt="02"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/03.webp"
        alt="03"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/04.webp"
        alt="04"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/05.webp"
        alt="05"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/06.webp"
        alt="06"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/07.webp"
        alt="07"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/08.webp"
        alt="08"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/09.webp"
        alt="09"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/10.webp"
        alt="10"
        className="pubs-img"
      ></img>
      <video autoPlay loop muted className="pubs-img">
        <source src={qf11} type="video/mp4" />
      </video>
      <img
        src="imgs/queimaDasFitas21/12.webp"
        alt="12"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/13.webp"
        alt="13"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/14.webp"
        alt="14"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/15.webp"
        alt="15"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/16.webp"
        alt="16"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/17.webp"
        alt="17"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/18.webp"
        alt="18"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/19.webp"
        alt="19"
        className="pubs-img"
      ></img>
      <img
        src="imgs/queimaDasFitas21/20.webp"
        alt="20"
        className="pubs-img"
      ></img>
    </div>
  );
};

export default PubsQueima;
