import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import Home from "./Pages/Home";
import Work from "./Pages/Work";
import Samples from "./Pages/Samples";
import About from "./Pages/About";
import LandingPage from "./Pages/LandingPage";
import QueimaDasFitas from "./Pages/Components/Projects/QueimaDasFitas21";
import AltaBaixa from "./Pages/Components/Projects/AltaBaixa";
import PauloMartins from "./Pages/Components/Projects/PauloMartins";
import Sykes from "./Pages/Components/Projects/Sykes";
import SkillX from "./Pages/Components/Projects/SkillX";
import PortoBC from "./Pages/Components/Projects/PortoBC";
import CultureInn from "./Pages/Components/Projects/CultureInn";
import VolumetricWorld from "./Pages/Components/Projects/VolumetricWorld";
import NvAltaBaixa from "./Pages/Components/Projects/2024/NvAltaBaixa";
import Thesis from "./Pages/Components/Projects/2024/Thesis";
import ZerooPantry from "./Pages/Components/Projects/2024/ZerooPantry";
import ZerooStand from "./Pages/Components/Projects/2024/ZerooStand";
import "./styles/application.scss";
import DotRing from "./Pages/Components/DotRing/DotRing";
import ScrollToTop from "./Pages/Components/ScrollToTop";

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <DotRing />
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Work />} exact />
          <Route path="/work" element={<Work />} exact />
          <Route path="/samples" element={<Samples />} exact />
          <Route path="/about" element={<About />} exact />
          <Route path="/2024" element={<LandingPage />} exact />

          <Route path="/queimadasfitas21" element={<QueimaDasFitas />} exact />
          <Route path="/altabaixa" element={<AltaBaixa />} exact />
          <Route path="/paulomartins" element={<PauloMartins />} exact />
          <Route path="/sykes" element={<Sykes />} exact />
          <Route path="/skillx" element={<SkillX />} exact />
          <Route path="/portobc" element={<PortoBC />} exact />
          <Route path="/cultureinn" element={<CultureInn />} exact />
          <Route path="/volumetricworld" element={<VolumetricWorld />} exact />
          <Route path="/thesis" element={<Thesis />} exact />
          <Route path="/nv-altabaixa" element={<NvAltaBaixa />} exact />
          <Route path="/zeroo-pantry" element={<ZerooPantry />} exact />
          <Route path="/zeroo-stand" element={<ZerooStand />} exact />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
