import React, { useContext } from "react";

import { MouseContext } from "./../Components/Context/mouse-context";

const ProjectCard = ({ page, title, image, tags, modifier }) => {
  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <div className="project-card">
      <div className="tags-container">
        {tags &&
          tags.map((tag, index) => (
            <div key={index} className="tag">
              {tag}
            </div>
          ))}
      </div>
      <a
        href={page}
        className={`project-card__content-container ${
          modifier ? modifier : ""
        }`}
        onMouseEnter={() => cursorChangeHandler("hovered-social")}
        onMouseLeave={() => cursorChangeHandler("")}
      >
        <img src={image} alt="project_image" className="project-card__image" />
      </a>
      <p className="project-card__title">{title}</p>
    </div>
  );
};

export default ProjectCard;
