import React from "react";

const formatTextWithLineBreaks = (text) => {
  return text.split("\n").map((line, index) => (
    <span key={index}>
      {line.trim()}
      {index < text.split("\n").length - 1}
    </span>
  ));
};

const ProjectSection = ({
  title,
  intro,
  subtitleLeft,
  subtitleRight,
  textLeft,
  textRight,
  subtitle3,
  text3,
}) => {
  const formattedTextRight = textRight
    ? formatTextWithLineBreaks(textRight)
    : null;

  return (
    <div className="project-section">
      <div className="project-section__intro">
        <div className="project-section__title">{title}</div>
        <div className="border"></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
          }}
        >
          {intro && <div className="project-section__paragraph">{intro}</div>}
          {subtitleLeft && subtitleRight && textLeft && textRight && (
            <div className="box-box">
              <div className="box-box__column">
                <div className="project-section__paragraph">{subtitleLeft}</div>
                <div className="project-section__gray-text">{textLeft}</div>
              </div>
              <div className="box-box__column">
                <div className="project-section__paragraph">
                  {subtitleRight}
                </div>
                <div
                  className="project-section__gray-text"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {formattedTextRight}
                </div>
              </div>
              {subtitle3 && text3 && (
                <div className="box-box__column">
                  <div className="project-section__paragraph">{subtitle3}</div>
                  <div
                    className="project-section__gray-text"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {text3}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/*-------------- ----------------------------*/}
    </div>
  );
};

export default ProjectSection;
