import React from "react";

const ProjectsIntro = ({ title, type, teamTitle, team, date, description }) => {
  return (
    <div className="box">
      <div className="projects-intro">
        <div className="projects-intro-left">
          <div className="projects-intro-title">{title}</div>
          <div className="projects-intro-type">{type}</div>

          <div className="projects-intro-team">
            <span>{teamTitle}</span>
            <div className="projects-intro-data team">{team}</div>
          </div>
        </div>

        <div className="projects-intro-date">
          <span>Date</span>
          <div className="projects-intro-data date">{date}</div>
        </div>

        <div className="projects-intro-right">
          <div className="projects-intro-data description">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsIntro;
