import React from "react";
//import { useContext } from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
import CharSykes from "../CharSykes";
import sykesMotion from "../videos/sykes-motion.mp4";
//import { MouseContext } from "../Context/mouse-context";
import setBodyColor from "../setBodyColor";

const Sykes = () => {
  //const { cursorChangeHandler } = useContext(MouseContext);

  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="sykes">
        <div className="sykes-intro">
          <ProjectsIntro
            title="sykes"
            type="Academic"
            teamTitle="Team"
            team="Carlota Silva Ricardo Sacadura"
            date="Jan. 2022"
            description="This project introduces the importance of 
          typography and aims to realize an experimental modular typeface, 
          from elements found in typeface details found in the streets of 
          the city of Coimbra, Portugal.  The characters and formal characteristics 
          were conceived having in mind their use for titles."
          />
        </div>

        <img src="imgs/sykes/banner.webp" alt="banner" className="banner"></img>

        <div className="sykes-project">
          <div className="sykes-text-title">sykes</div>
          <div className="sykes-text-600">600 px</div>
          <section>
            <div className="scroll-sykes">
              <div>AaBbCcDdEe123?!&@&nbsp;</div>
              <div>AaBbCcDdEe123?!&@&nbsp;</div>
            </div>
          </section>

          <section className="styles-soon">
            <div className="scroll text-1">
              <div>&nbsp;STYLES SOON&nbsp;</div>
              <div>&nbsp;STYLES SOON&nbsp;</div>
            </div>
          </section>
          <div style={{ position: "relative" }}>
            <div className="sykes-text-phrase">
              "The study confirms that the brain is a modular system comprising
              multiple intelligences, mostly nonverbal"
            </div>
            <div className="sykes-text-author">
              — Steven Pinker "On Einstein's brain," The New York Times (June
              24, 1999)
            </div>
          </div>

          <div className="posters-wrapper">
            <div className="single">
              <img
                src="imgs/sykes/poster_1.webp"
                alt="poster_1"
                className="poster-1"
              ></img>
            </div>

            <div className="single">
              <img
                src="imgs/sykes/poster_2.webp"
                alt="poster_2"
                className="poster-2"
              ></img>
            </div>
          </div>

          <div className="characters">
            <div className="sykes-text-characteres">Sykes Regular</div>

            <CharSykes />
          </div>

          <video autoPlay loop muted className="sykes-video">
            <source src={sykesMotion} type="video/mp4" />
          </video>

          <div className="variations">
            <div className="sykes-text-characteres">Variations</div>

            <img
              src="imgs/sykes/variations.svg"
              alt="variations"
              style={{ width: "-webkit-fill-available" }}
            ></img>
          </div>

          <div className="more">
            <div className="sykes-text-more">more to come</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Sykes;
