import React from "react";
import { useState, useContext } from "react";
import { Cross as Hamburger } from "hamburger-react";
import { NavLink } from "react-router-dom";
import { MouseContext } from "../Context/mouse-context";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "overlay";
  }

  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <div className="navbar">
      <li>
        <NavLink
          onMouseEnter={() => cursorChangeHandler("hovered-logo")}
          onMouseLeave={() => cursorChangeHandler("")}
          exact="true"
          to="/"
          className={isOpen ? "logo-navbar switch" : "logo-navbar"}
        >
          notfound.
        </NavLink>
      </li>

      <Hamburger
        toggled={isOpen}
        toggle={setOpen}
        size={28}
        distance="sm"
        color={isOpen ? "#000000" : "#f0f0f2"}
      />

      <div className={isOpen ? "navbar-menu active" : "navbar-menu"}>
        <li>
          <NavLink
            onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
            onMouseLeave={() => cursorChangeHandler("")}
            exact="true"
            to="/"
            className={({ isActive }) =>
              "text-menu-elements" +
              (isActive ? " text-menu-elements active" : "")
            }
          >
            work
          </NavLink>
        </li>

        <li>
          <NavLink
            onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
            onMouseLeave={() => cursorChangeHandler("")}
            exact="true"
            to="/samples"
            className={({ isActive }) =>
              "text-menu-elements" +
              (isActive ? " text-menu-elements active" : "")
            }
          >
            samples
          </NavLink>
        </li>

        <li>
          <NavLink
            onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
            onMouseLeave={() => cursorChangeHandler("")}
            exact="true"
            to="/about"
            className={({ isActive }) =>
              "text-menu-elements" +
              (isActive ? " text-menu-elements active" : "")
            }
          >
            about
          </NavLink>
        </li>
      </div>
    </div>
  );
};

export default Navbar;
