import React from "react";

const CardData = ({ title, number, description, icon }) => {
  return (
    <div className="card-data">
      <div className="card-data__title">{title}</div>
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {icon && (
            <span className="material-icons card-data__icon">{icon}</span>
          )}
          <p className="card-data__number">{number}</p>
        </div>

        <p className="project-section__gray-text">{description}</p>
      </div>
    </div>
  );
};

export default CardData;
