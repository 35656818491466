import React, { useContext } from "react";
import { MouseContext } from "./Context/mouse-context";

const Avatar = ({ image, title, link }) => {
  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <a
      href={link}
      className="avatar"
      onMouseEnter={() => cursorChangeHandler("hovered-social")}
      onMouseLeave={() => cursorChangeHandler("")}
      rel="noreferrer"
      target="_blank"
    >
      <div className="avatar__image-container">
        <img src={image} alt="project_image" className="avatar__image" />
      </div>
      <p className="avatar__title">{title}</p>
    </a>
  );
};

export default Avatar;
