import React, { useEffect, useState, useContext } from "react";
import setBodyColor from "./Components/setBodyColor";
import { MouseContext } from "./Components/Context/mouse-context";
import NewNavbar from "./Components/Navbar/NewNavbar";
import ProjectCard from "./Components/ProjectCard";

const images = [
  "imgs/samples/b21.webp",
  "imgs/samples/b221.webp",
  "imgs/samples/n21.webp",
  "imgs/samples/m21.webp",
  "imgs/samples/g21.webp",
  "imgs/samples/g221.webp",
  "imgs/samples/c21.webp",
  "imgs/samples/d21.webp",
];

const LandingPage = () => {
  const { cursorChangeHandler } = useContext(MouseContext);

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  setBodyColor({ color: "#f8f8f8" });

  return (
    <div>
      <NewNavbar />
      <div className="mobile-content">
        <div className="loader">
          Mobile magic is coming soon! <br />
          For now, grab your laptop or desktop for the full feast. <br />
          <br />
          Thanks for your patience!
        </div>
      </div>
      <div className="landing-page">
        {/* <div className="top-page-banner">
        <div className="top-page-banner__content">
          <div className="top-page-banner__emoji">🤗 </div>
          <div className="top-page-banner__text">
            Guess who’s back, back again?
            <span> Tell a friend</span>
          </div>
          <div className="top-page-banner__emoji">🤗 </div>
          <div className="top-page-banner__text">
            Guess who’s back, back again?
            <span> Tell a friend</span>
          </div>
          <div className="top-page-banner__emoji">🤗 </div>
          <div className="top-page-banner__text">
            Guess who’s back, back again?
            <span> Tell a friend</span>
          </div>
          <div className="top-page-banner__emoji">🤗 </div>
          <div className="top-page-banner__text">
            Guess who’s back, back again?
            <span> Tell a friend</span>
          </div>
          <div className="top-page-banner__emoji">🤗 </div>
          <div className="top-page-banner__text">
            Guess who’s back, back again?
            <span> Tell a friend</span>
          </div>
        </div>
      </div> */}

        <div className="top-page-content">
          <div className="banner-mask-container">
            <div className="banner-mask-container__title">
              Crafting Meaningful Experiences: <br />
              The Portfolio of Alexandre Pinho
            </div>
            <svg
              className="banner-mask-container__svg"
              viewBox="0 0 1408 680"
              fill="none"
            >
              <defs>
                <clipPath id="clip-path">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1023.7 8.71084C1020.97 3.36396 1015.47 0 1009.46 0H398.538C392.532 0 387.031 3.36395 384.295 8.71084L360.458 55.2892C357.722 60.636 352.221 64 346.215 64H16C7.16344 64 0 71.1634 0 80V664C0 672.837 7.16343 680 16 680H1392C1400.84 680 1408 672.837 1408 664V80C1408 71.1634 1400.84 64 1392 64H1061.79C1055.78 64 1050.28 60.636 1047.54 55.2892L1023.7 8.71084Z"
                    fill="#838282"
                  />
                </clipPath>
              </defs>
              <image
                href={images[currentImage]}
                clipPath="url(#clip-path)"
                className="banner-mask-container__img"
              />
            </svg>
          </div>
          <div className="top-page-content__bottom-page">
            <div className="content-social">
              <a
                onMouseEnter={() => cursorChangeHandler("hovered-social")}
                onMouseLeave={() => cursorChangeHandler("")}
                className="social-btn"
                href="https://www.instagram.com/notf_found/"
                rel="noreferrer"
                target="_blank"
              >
                INSTAGRAM
                <span className="material-icons btn-icon btn-icon--secondary">
                  arrow_outward
                </span>
              </a>
              <a
                onMouseEnter={() => cursorChangeHandler("hovered-social")}
                onMouseLeave={() => cursorChangeHandler("")}
                className="social-btn"
                href="https://www.behance.net/alexandrepinho"
                rel="noreferrer"
                target="_blank"
              >
                BEHANCE
                <span className="material-icons btn-icon btn-icon--secondary">
                  arrow_outward
                </span>
              </a>
              <a
                onMouseEnter={() => cursorChangeHandler("hovered-social")}
                onMouseLeave={() => cursorChangeHandler("")}
                className="social-btn"
                href="https://dribbble.com/not_found"
                rel="noreferrer"
                target="_blank"
              >
                DRIBBBLE
                <span className="material-icons btn-icon btn-icon--secondary">
                  arrow_outward
                </span>
              </a>
              <a
                onMouseEnter={() => cursorChangeHandler("hovered-social")}
                onMouseLeave={() => cursorChangeHandler("")}
                className="social-btn"
                href="https://github.com/alexpinho"
                rel="noreferrer"
                target="_blank"
              >
                GITHUB
                <span className="material-icons btn-icon btn-icon--secondary bounce">
                  arrow_outward
                </span>
              </a>
            </div>
            <div className="text-comming-soon">
              v2.0 coming soon. <span>Be ready!</span>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="selected-works">
            <div className="selected-works__header">
              <div className="selected-works__title">Selected Works</div>
              <div className="selected-works__date">2021-2024</div>
            </div>
          </div>
          <div className="projects-frame">
            <div className="row">
              <ProjectCard
                page="/zeroo-stand"
                title="Increased conversion rate to 80% in a new in-store digital bulk solution"
                image="imgs/zerooStand/zeroo-stand-banner.webp"
                tags={["UX", "UI", "3D", "GRAPHIC"]}
                modifier="project-card__content-container--stand"
              />
            </div>
            <div className="row">
              <div className="project-card">
                <div className="tags-container">
                  <div className="tag">AI</div>
                  <div className="tag">UX</div>
                  <div className="tag">3D RECONSTRUCTION</div>
                </div>
                <a
                  href="/thesis"
                  className="project-card__content-container"
                  onMouseEnter={() => cursorChangeHandler("hovered-social")}
                  onMouseLeave={() => cursorChangeHandler("")}
                >
                  <video
                    controls={false}
                    autoPlay
                    loop
                    muted
                    src="imgs/360-4-02.mp4"
                    type="video/mp4"
                    className="project-card__video"
                  />
                </a>
                <p className="project-card__title">
                  Impact of 3D image reconstruction on user experience in a
                  circular economy context
                </p>
              </div>
              <ProjectCard
                page="/zeroo-pantry"
                title="Evolution of Zeroo Digital Pantry to promote sustainable consumption"
                image="imgs/zerooPantry/pantry-banner.webp"
                tags={["AI", "UX", "UI", "3D", "GRAPHIC"]}
              />
            </div>
            <div className="row">
              <ProjectCard
                page="/nv-altabaixa"
                title="Alta & Baixa - Typography Magazine"
                image="imgs/altaBaixa/p-11b.webp"
                tags={["TYPOGRAPHY", "GRAPHIC"]}
              />
              <ProjectCard
                page="https://www.behance.net/gallery/121678267/CultureInn-Event-app"
                title="CultureInn - Event App"
                image="imgs/CultureInn-banner.webp"
                tags={["UX", "UI"]}
              />
            </div>
          </div>
        </div>
        <div className="new-footer">© 2024, NOT FOUND.</div>
      </div>
    </div>
  );
};

export default LandingPage;
