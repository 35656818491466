import React from "react";
import setBodyColor from "../../setBodyColor";
import NewNavbar from "../../Navbar/NewNavbar";
import Avatar from "../../Avatar";
import ProjectSection from "../../ProjectSection";

const ZerooPantry = () => {
  setBodyColor({ color: "#f8f8f8" });

  return (
    <div>
      <NewNavbar />
      <div className="mobile-content">
        <div className="loader">
          Mobile magic is coming soon! <br />
          For now, grab your laptop or desktop for the full feast. <br />
          <br />
          Thanks for your patience!
        </div>
      </div>
      <div className="project-page">
        <div className="project-page__content">
          <div className="header">
            <div className="header__content">
              <div className="header__top">
                <div className="header__role">
                  <span>Product Designer</span>
                  <span>•</span>
                  <span>2022-2024</span>
                </div>
                <div className="tags-container tags-container--project">
                  <div className="tag">AI</div>
                  <div className="tag">UX</div>
                  <div className="tag">UI</div>
                  <div className="tag">3D</div>
                  <div className="tag">GRAPHIC</div>
                </div>
              </div>
              <div className="header__title">
                <span>
                  Evolution of Zeroo Digital Pantry to promote sustainable
                  consumption
                </span>
              </div>
            </div>
            <div className="header__teams">
              <Avatar
                title="Zeroo"
                image="imgs/Avatars/team-zeroo.webp"
                link="https://www.zeroo.pt/"
              />
            </div>
          </div>

          <img
            src="imgs/zerooPantry/pantry-banner.webp"
            alt="project_banner"
            className="project-page__banner"
          />
        </div>
        <div className="project-content">
          <div className="project-content__intro">
            Zeroo is on a mission to end single-use packaging through reusable
            and digital packaging solutions. We aim to inspire a movement that
            empowers producers, retailers, and consumers to create a better
            future for generations to come. <br /> I was fortunate to be
            involved in this project from almost the very beginning,
            contributing to its evolution and helping shape its experience.
          </div>

          {/*-------------- AREAS OF FOCUS----------------------------*/}
          <ProjectSection
            title="Areas of Focus"
            intro="Our goal was to create a platform that empowers users to make informed choices and embrace more sustainable lifestyle addressing plastic waste. We saw an opportunity to leverage technology to create a positive environmental impact."
            subtitleLeft="Problem"
            textLeft="The world produces 438 million tons of plastic annually, with 158 million tons from plastic packaging. This contributes significantly to environmental pollution. There are still many gaps in the market for a user-friendly digital solution to help households reduce plastic waste and manage their food inventory."
            subtitleRight="Business Goals"
            textRight={`• Reduce household food waste by providing accurate tracking and reminders;\n
    • Encourage sustainable consumption habits with educational content and tips;\n
    • Develop a seamless user experience that integrates into daily routines.`}
          />
          {/*-------------- ----------------------------*/}
          {/*-------------- APPROACH AND LIMITATIONS----------------------------*/}
          <ProjectSection
            title="The Approach and Limitations"
            subtitleLeft="Approach"
            textLeft="We adopted a user-centered approach, focusing on understanding the needs and behaviors of our target audience. We conducted surveys and interviews, to gather insights and to iterate quickly based on feedback, ensuring our solution was effective and user-friendly."
            subtitleRight="Limitations"
            textRight="We faced budget and resource constraints. Ensuring user adoption and engagement required continuous refinement of our strategies."
          />
          {/*-------------- ----------------------------*/}
          {/*-------------- HOME BASE FOR DISCOVERY----------------------------*/}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "5rem" }}
          >
            <ProjectSection
              title="Home base for discovery"
              intro="Users are frustrated with the excessive packaging and inefficiencies in the bulk shopping process. These insights guided our development, ensuring the solution addressed these pain points and supported users' sustainable consumption goals."
              discovery="imgs/zerooPantry/discovery.webp"
            />

            <img
              src="imgs/zerooPantry/discovery.webp"
              alt="project_image"
              className="discovery-image"
            />
          </div>
          {/*-------------- ----------------------------*/}

          {/*-------------- BRAND FEEL----------------------------*/}
          <div className="project-section">
            <div className="project-section__intro">
              <div className="project-section__title">Develop Brand feel</div>
              <div className="border"></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div className="box-box__column">
                    <div className="project-section__paragraph">Expressive</div>
                    <div className="project-section__gray-text">
                      The visual identity of Zeroo was crafted to convey
                      sustainability and eco-friendliness.
                    </div>
                  </div>
                  {/*--------------box-box__column ----------------------------*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4rem",
                    }}
                  >
                    <img
                      src="imgs/zerooPantry/brand-feel-1.webp"
                      alt="project_image"
                      className="project-page__banner"
                    />
                    {/*--------------card 2----------------------------*/}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.75rem",
                      }}
                    >
                      <img
                        src="imgs/zerooPantry/brand-feel-2.webp"
                        alt="project_image"
                        className="project-page__banner"
                      />
                      <div className="project-section__gray-text">
                        3D models of Zeroo cups are modular, offering
                        flexibility to accommodate an infinite variety of
                        products, <br />
                        making the digital pantry experience as realistic as
                        possible.
                      </div>
                    </div>
                    {/*--------------card 2----------------------------*/}
                    {/*--------------card 3----------------------------*/}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.75rem",
                      }}
                    >
                      <img
                        src="imgs/zerooPantry/brand-feel-3.webp"
                        alt="project_image"
                        className="project-page__banner"
                      />
                      <div className="project-section__gray-text">
                        AI-generated visual assets streamline the creation
                        process, allowing more time to focus on enhancing the
                        user experience.
                      </div>
                    </div>
                    {/*--------------card 3----------------------------*/}
                    {/*--------------card 4----------------------------*/}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2.5rem",
                      }}
                    >
                      <div className="box-box__column">
                        <div className="project-section__paragraph">
                          Expressive
                        </div>
                        <div className="project-section__gray-text">
                          The visual identity of Zeroo was crafted to convey
                          sustainability and eco-friendliness.
                        </div>
                      </div>
                      <img
                        src="imgs/zerooPantry/prototype-1.webp"
                        alt="project_image"
                        className="project-page__banner"
                      />
                    </div>
                    {/*--------------card 4----------------------------*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*-------------- ----------------------------*/}
          {/*-------------- Feature exploration----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <ProjectSection title="Feature exploration" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4rem",
                padding: "0 10%",
              }}
            >
              {/*--------------card 1----------------------------*/}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="box-box__column">
                  <div className="project-section__paragraph">
                    Multiple products
                  </div>
                  <div className="project-section__gray-text">
                    Float menu to simplify product navigation when consulting
                    product information in the same Zeroo cup.
                  </div>
                </div>
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/zerooPantry/app-video-multiple.mp4"
                  type="video/mp4"
                  className="project-page__banner"
                />
              </div>
              {/*--------------card 1----------------------------*/}
              {/*--------------card 2----------------------------*/}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="box-box__column">
                  <div className="project-section__paragraph">
                    Tips and Educational content
                  </div>
                  <div className="project-section__gray-text">
                    Create interactive tutorials to boost user engagement and
                    retention.
                  </div>
                </div>
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/zerooPantry/app-video-onboarding.mp4"
                  type="video/mp4"
                  className="project-page__banner"
                />
              </div>
              {/*--------------card 2----------------------------*/}
              {/*--------------card 3----------------------------*/}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="box-box__column">
                  <div className="project-section__paragraph">
                    Add Zeroo package
                  </div>
                  <div className="project-section__gray-text">
                    CAllow users to add their Zeroo cup to the pantry by
                    scanning a QR code or entering a code, streamlining the
                    process and enabling multiple additions in sequence.
                  </div>
                </div>
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/zerooPantry/app-video-add-package.mp4"
                  type="video/mp4"
                  className="project-page__banner"
                />
              </div>
              {/*--------------card 3----------------------------*/}
            </div>
          </div>
          {/*-------------- ----------------------------*/}
          {/*-------------- FINAL----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <ProjectSection title="Final Design" />
            <img
              src="imgs/zerooPantry/final.webp"
              alt="project_image"
              className="project-page__banner"
            />
          </div>
          {/*-------------- ----------------------------*/}
        </div>
        <div className="new-footer">© 2024, NOT FOUND.</div>
      </div>
    </div>
  );
};

export default ZerooPantry;
