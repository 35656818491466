import React from "react";
import setBodyColor from "../../setBodyColor";
import NewNavbar from "../../Navbar/NewNavbar";
import Avatar from "../../Avatar";
import ProjectSection from "../../ProjectSection";
import CardData from "../../CardData";

const ZerooStand = () => {
  setBodyColor({ color: "#f8f8f8" });

  return (
    <div>
      <NewNavbar />
      <div className="mobile-content">
        <div className="loader">
          Mobile magic is coming soon! <br />
          For now, grab your laptop or desktop for the full feast. <br />
          <br />
          Thanks for your patience!
        </div>
      </div>
      <div className="project-page">
        {/*-------------- Header----------------------------*/}
        <div className="project-page__content">
          <div className="header">
            <div className="header__content">
              <div className="header__top">
                <div className="header__role">
                  <span>Product Designer</span>
                  <span>•</span>
                  <span>2022-2024</span>
                </div>
                <div className="tags-container tags-container--project">
                  <div className="tag">UX</div>
                  <div className="tag">UI</div>
                  <div className="tag">3D</div>
                  <div className="tag">GRAPHIC</div>
                </div>
              </div>
              <div className="header__title">
                <span>
                  Increased conversion rate to 80% in a new in-store digital
                  bulk solution
                </span>
              </div>
            </div>
            <div className="header__teams">
              <Avatar
                title="Zeroo"
                image="imgs/Avatars/team-zeroo.webp"
                link="https://www.zeroo.pt/"
              />
            </div>
          </div>
          <img
            src="imgs/zerooStand/bulk-store-front-site.webp"
            alt="project_banner"
            className="project-page__banner"
          />
        </div>
        {/*-------------- Header----------------------------*/}
        {/*-------------- Content----------------------------*/}
        <div className="project-content">
          <div className="project-content__intro">
            Zeroo is on a mission to end single-use packaging through reusable
            and digital packaging solutions. <br />
            My role involved ensuring an optimal user experience, developing the
            visual identity, and collaborating with cross-functional teams to
            bring the project to life. Our goal was to create a digital system
            that helps bulk shoppers in grocery stores buy in a more convenient
            and sustainable way.
          </div>
          {/*-------------- Key accomplishments----------------------------*/}
          <ProjectSection
            title="Key accomplishments"
            subtitleLeft="Efficient Time Management"
            textLeft="Understanding that certain solutions require additional development time and sticking to realistic deadlines ensures cost awareness, leading to efficient project execution."
            subtitleRight="Requirements Management"
            textRight="Ensuring solutions align with specified requirements during revisions helps avoid costly rework and guarantees the final product meets expectations."
            subtitle3="Data-Driven Decision-Making"
            text3="Balancing quick responses with thorough analysis ensures well-informed decisions and high-quality design outcomes."
          />
          {/*-------------- ----------------------------*/}
          {/*-------------- Challenges & Learnings----------------------------*/}
          <ProjectSection
            title="Challenges & Learnings"
            subtitleLeft="Navigating Technical Challenges"
            textLeft="We faced extended development cycles and the need to simplify features while maintaining performance and usability. This taught me to adapt and find innovative solutions under technical constraints."
            subtitleRight="Enhancing Communication Tools"
            textRight="The initial video produced was captivating but not effective as an instructional tool for the app. This experience taught me the importance of advocating for more effective solutions to improve user understanding and interaction."
          />
          {/*-------------- ----------------------------*/}
          {/*-------------- Areas of focus----------------------------*/}
          <ProjectSection
            title="Areas of Focus"
            subtitleLeft="Problem"
            textLeft="The excessive use of single-use plastic packaging in grocery stores contributes significantly to environmental pollution and waste. Consumers often face inconvenience and lack of sustainable options when shopping for bulk items, leading to continued reliance on disposable packaging."
            subtitleRight="Succes metrics"
            textRight={`• Reduction in plastic waste reported by users;\n
            • Increased user engagement and retention;\n
            • Positive user feedback and satisfaction.`}
          />
          {/*-------------- ----------------------------*/}
          {/*-------------- Approoach and Process----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5rem",
            }}
          >
            <ProjectSection
              title="Approach and Process"
              subtitleLeft="Approach"
              textLeft="We adopted a user-centered approach, focusing on understanding the needs and behaviors of our target audience. By involving users in testing prototypes at every stage, we gathered insights and iterated quickly based on feedback, ensuring our solution was both effective and user-friendly."
              subtitleRight="Key findings"
              textRight={`• Unclear instructions for cup filling;\n
            • Misleading perception of automatic filling with gravity dispensers;\n
            • Confusion about cups location and available sizes.`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: "0 10%",
              }}
            >
              <div className="box-box__column">
                <div className="project-section__paragraph">Discovery</div>
                <div className="project-section__gray-text">
                  Continuous testing of assumptions helped us refine our
                  solutions and validate our design choices effectively.
                </div>
              </div>
              <img
                src="imgs/zerooStand/discovery.webp"
                alt="project_image"
                className="project-page__banner"
              />
            </div>
          </div>
          {/*-------------- Approoach and Process----------------------------*/}
          {/*-------------- Core Explorations----------------------------*/}
          <div className="project-section">
            <div className="project-section__intro">
              <div className="project-section__title">Core Explorations</div>
              <div className="border"></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div className="box-box__column">
                    <div className="project-section__paragraph">
                      Reader design
                    </div>
                    <div className="project-section__gray-text">
                      • Collaborated closely with the R&D team <br />
                      • Adapted the design to address shape constraints 
                      <br />• Transitioned the process to a new partner for
                      further development
                    </div>
                  </div>
                  <img
                    src="imgs/zerooStand/reader-exp.webp"
                    alt="project_image"
                    className="project-page__banner"
                  />
                  <img
                    src="imgs/zerooStand/reader-3d-exp.webp"
                    alt="project_image"
                    className="project-page__banner"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div className="box-box__column">
                    <div className="project-section__paragraph">
                      Ready-to-fill screen
                    </div>
                    <div className="project-section__gray-text">
                      • Simplified layout to help users easily find prices{" "}
                      <br />
                      • Removed distracting partner colors to enhance user focus
                      <br />• Improved accessibility by providing clearer next
                      steps for users and incorporating voice audio guidance
                    </div>
                  </div>
                  <img
                    src="imgs/zerooStand/ready-fill-app.webp"
                    alt="project_image"
                    className="project-page__banner"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <div className="box-box__column">
                    <div className="project-section__paragraph">
                      Stand Design
                    </div>
                    <div className="project-section__gray-text">
                      • Iteratively developed and refined, transforming the
                      initial concept into a polished final design that met all
                      user and business requirements <br />
                      • Enhanced communication of the Zeroo cup technology
                      <br />• Organized cups with dividers to clearly identify
                      different sizes
                    </div>
                  </div>
                  <img
                    src="imgs/zerooStand/stand-exp.webp"
                    alt="project_image"
                    className="project-page__banner"
                  />
                  <img
                    src="imgs/zerooStand/stand-real-exp.webp"
                    alt="project_image"
                    className="project-page__banner"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*-------------- Core Explorations----------------------------*/}
          {/*-------------- FINAL----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem",
            }}
          >
            <ProjectSection title="Final Design" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="project-section__paragraph"
                  style={{ padding: "0 10%" }}
                >
                  Stand interface
                </div>
                <img
                  src="imgs/zerooStand/final-app.webp"
                  alt="project_image"
                  className="project-page__banner"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                }}
              >
                <div
                  className="project-section__paragraph"
                  style={{ padding: "0 10%" }}
                >
                  Stand Design
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                    padding: "0 1rem",
                  }}
                >
                  <img
                    src="imgs/zerooStand/final-stand.webp"
                    alt="project_image"
                    className="project-page__banner"
                  />
                  <div className="project-section__gray-text">
                    Zeroo Stand 3D model
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  padding: "0 1rem",
                }}
              >
                <div className="card-image__container">
                  <img
                    src="imgs/zerooStand/final-stand-1.webp"
                    alt="project_image"
                    className="card-image"
                  />
                </div>
                <div className="card-image__container">
                  <img
                    src="imgs/zerooStand/final-stand-2.webp"
                    alt="project_image"
                    className="card-image"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  gap: "0.75rem",
                }}
              >
                <video
                  controls={false}
                  autoPlay
                  loop
                  muted
                  src="imgs/zerooStand/Zeroo-stand.mp4"
                  type="video/mp4"
                  className="zeroo-stand-video"
                />
                <div className="project-section__gray-text">
                  Zeroo Stand experience
                </div>
              </div>
            </div>
          </div>
          {/*-------------- ----------------------------*/}
          {/*-------------- BUSINESS IMPACT----------------------------*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5rem",
            }}
          >
            <ProjectSection
              title="Business Impact"
              intro="The experimental design yielded a significant improvement in user engagement and overall performance."
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5rem",
                padding: "0 10%",
              }}
            >
              <CardData
                title="CONVERSION RATE"
                number="80%"
                description="The experimental design boosted the conversion rate, highlighting the success of our user-centered approach."
                icon="arrow_upward_alt"
              />
              <CardData
                title="TIME PERCEPTION"
                number="70%"
                description="Users felt the process met their time expectations, indicating enhanced user satisfaction and efficiency."
              />
              <CardData
                title="RECOMMENDATION LIKELIHOOD"
                number="High"
                description="A large percentage of users expressed their likelihood to recommend the system, showcasing potential for broader adoption."
              />
            </div>
          </div>
          {/*-------------- ----------------------------*/}
        </div>
        {/*-------------- Content----------------------------*/}
        <div className="new-footer">© 2024, NOT FOUND.</div>
      </div>
    </div>
  );
};

export default ZerooStand;
