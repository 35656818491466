import React from "react";
//import { useContext } from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
//import { MouseContext } from "../Context/mouse-context";
import setBodyColor from "../setBodyColor";

const PauloMartins = () => {
  //const { cursorChangeHandler } = useContext(MouseContext);
  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="paulomartins">
        <ProjectsIntro
          title="paulo martins"
          type="Academic"
          teamTitle="Team"
          team="Alexandre Lucas Ricardo Sacadura"
          date="Dec. 2021"
          description="This year we was presented with a project for the 
          Web Development class, which focuses on the design of websites 
          with high aesthetic value and, in parallel, on their development 
          with good functionality and the correct technical execution, 
          carried out by a group of 3 people. As we knew Paulo Martins' work, 
          we decided to contact him to make use of his image."
        />

        <section>
          <div className="scroll text-1">
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PauloMartins;
