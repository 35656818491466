import React from "react";
import o1 from "./videos/o1.mp4";
import o2 from "./videos/o2.mp4";
import o3 from "./videos/o3.mp4";

const MasonrySamples = () => {
  return (
    <div className="samples-masonry">
      <div className="brick">
        <video autoPlay loop muted>
          <source src={o2} type="video/mp4" />
        </video>
        <div className="overlay">
          <div className="text-post-samples">m XXI</div>
        </div>
      </div>

      <div className="brick">
        <img src="imgs/samples/b221.webp" alt="b221"></img>
        <div className="overlay">
          <div className="text-post-samples">b2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/l21.webp" alt="l21"></img>
        <div className="overlay">
          <div className="text-post-samples">l XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/h221.webp" alt="h221"></img>
        <div className="overlay">
          <div className="text-post-samples">h2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/a21.webp" alt="a21"></img>
        <div className="overlay">
          <div className="text-post-samples">a XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/k21.webp" alt="k21"></img>
        <div className="overlay">
          <div className="text-post-samples">k XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/b21.webp" alt="b21"></img>
        <div className="overlay">
          <div className="text-post-samples">b XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/o221.webp" alt="o221"></img>
        <div className="overlay">
          <div className="text-post-samples">o2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/e21.webp" alt="e21"></img>
        <div className="overlay">
          <div className="text-post-samples">e XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/g21.webp" alt="g21"></img>
        <div className="overlay">
          <div className="text-post-samples">g XXI</div>
        </div>
      </div>
      <div className="brick">
        <video autoPlay loop muted>
          <source src={o3} type="video/mp4" />
        </video>
        <div className="overlay">
          <div className="text-post-samples">i XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/e221.webp" alt="e221"></img>
        <div className="overlay">
          <div className="text-post-samples">e2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/j21.webp" alt="j21"></img>
        <div className="overlay">
          <div className="text-post-samples">j XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/g221.webp" alt="g221"></img>
        <div className="overlay">
          <div className="text-post-samples">g2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <video autoPlay loop muted>
          <source src={o1} type="video/mp4" />
        </video>
        <div className="overlay">
          <div className="text-post-samples">n2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/d21.webp" alt="d21"></img>
        <div className="overlay">
          <div className="text-post-samples">d XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/h21.webp" alt="h21"></img>
        <div className="overlay">
          <div className="text-post-samples">h XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/p21.webp" alt="p21"></img>
        <div className="overlay">
          <div className="text-post-samples">p XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/k221.webp" alt="k221"></img>
        <div className="overlay">
          <div className="text-post-samples">k2 XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/c21.webp" alt="c21"></img>
        <div className="overlay">
          <div className="text-post-samples">c XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/f21.webp" alt="f21"></img>
        <div className="overlay">
          <div className="text-post-samples">f XXI</div>
        </div>
      </div>
      <div className="brick">
        <img src="imgs/samples/o21.webp" alt="o21"></img>
        <div className="overlay">
          <div className="text-post-samples">o XXI</div>
        </div>
      </div>
    </div>
  );
};

export default MasonrySamples;
