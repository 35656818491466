import React from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
import setBodyColor from "../setBodyColor";

const CultureInn = () => {
  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="cultureinn">
        <ProjectsIntro
          title="cultureInn"
          type="Academic"
          teamTitle="Team"
          team="Carlota Silva Ricardo Sacadura"
          date="May 2021"
          description="CultureInn was a project made for Interactive Media Design. 
          We intend to create a tool to promote cultural well-being, wich we think 
          is an interesting, powerful and somehow unexpected decide to combat the 
          problem of emotional malaise. Our proposal is to design an app that fosters 
          cultural activity and provides stimulating challenges. The app works as a 
          “cultural calorie meter”."
        />

        <ul className="cultureinn-list">
          <li className="cultureinn-list-element">
            <a
              href="https://www.behance.net/gallery/121678267/CultureInn-Event-app"
              className="cultureinn-list-text text-see"
              rel="noopener noreferrer"
              target="_blank"
            >
              SEE ON BEHANCE
            </a>
          </li>
        </ul>

        <section>
          <div className="scroll text-1">
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CultureInn;
