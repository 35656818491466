import React from "react";
import { useContext } from "react";
import Layout from "./Components/Layout";
import { MouseContext } from "./Components/Context/mouse-context";
import setBodyColor from "./Components/setBodyColor";

const About = () => {
  const { cursorChangeHandler } = useContext(MouseContext);
  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="about">
        <div className="text-about">
          Hey! I'm Alexandre, a 21-year-old guy from Portugal. I appreciate that
          you want to know me a little more. I thought it would be fun to share
          my 3D samples because it allows me to improve a skill that I like. The
          objective is that they may be part of my work one day.
          <p /> From branding to web-dev, I want to share a few of my
          experiences so you can better understand my path. In my projects, I
          always try to pay attention to every detail, choose good typography,
          and have a well-defined system because it's crucial to work with the
          content in the best way possible and thus have a potential project.
          <br />
          <br />I hope you enjoy it!
        </div>

        <div className="social">
          <ul className="social-area">
            <li
              className="social-area-elements"
              onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                href="https://www.instagram.com/notf_found/"
                className="social-area-elements-text text-social"
                rel="noopener noreferrer"
                target="_blank"
              >
                instagram
              </a>
            </li>
            <li
              className="social-area-elements"
              onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                href="https://github.com/alexpinho"
                className="social-area-elements-text text-social"
                rel="noopener noreferrer"
                target="_blank"
              >
                github
              </a>
            </li>
            <li
              className="social-area-elements"
              onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                href="https://www.behance.net/alexandrepinho"
                className="social-area-elements-text text-social"
                rel="noopener noreferrer"
                target="_blank"
              >
                behance
              </a>
            </li>
            <li
              className="social-area-elements"
              onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                href="https://www.linkedin.com/in/alexandre-pinho-2159711b1/"
                className="social-area-elements-text text-social"
                rel="noopener noreferrer"
                target="_blank"
              >
                linkedin
              </a>
            </li>
            <li
              className="social-area-elements"
              onMouseEnter={() => cursorChangeHandler("hovered-nav-items")}
              onMouseLeave={() => cursorChangeHandler("")}
            >
              <a
                href="https://dribbble.com/not_found"
                className="social-area-elements-text text-social"
                rel="noopener noreferrer"
                target="_blank"
              >
                dribbble
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default About;
