import React from "react";
//import { useContext } from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
//import { MouseContext } from "../Context/mouse-context";
import setBodyColor from "../setBodyColor";

const SkillX = () => {
  //const { cursorChangeHandler } = useContext(MouseContext);

  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="skillx">
        <ProjectsIntro
          title="skillX"
          type="Summer Intern"
          teamTitle="Team"
          team="Whitesmith"
          date="Sep. 2021"
          description="This project was made during my summer internship, 
          where we were presented with the idea of creating a 'Skills Matchmaking's 
          application. To be able to define the object to be created we had to define 
          the problem and its space, as well as target service and objectives. The product 
          is an application/platform where people can get together according to their interests, 
          in order to share skills or knowledge."
        />

        <section>
          <div className="scroll text-1">
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SkillX;
