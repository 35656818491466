import React from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
import setBodyColor from "../setBodyColor";

const VolumetricWorld = () => {
  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="volumetricworld">
        <ProjectsIntro
          title="volumetric world"
          type="Academic"
          teamTitle=""
          team=""
          date="May 2021"
          description="I made this project for Modeling and Materialization. 
          The goal was to design, model and materialize a three-dimensional 
          construction in a fixed spatial volume. I thought it was a good 
          idea to have a construction that works with light and resembles 
          a puzzle at first time but very easy to understand. "
        />

        <img
          src="imgs/volumetricWorld/principal.png"
          alt="principal"
          className="volumetricworld-banner"
        ></img>

        <div className="volumetric-masonry">
          <div className="brick">
            <img
              src="imgs/volumetricWorld/front_plant.png"
              alt="front_plant"
            ></img>
            <div className="overlay">
              <div className="text-post-samples">FRONT PLANT</div>
            </div>
          </div>

          <div className="brick">
            <img src="imgs/volumetricWorld/top_plant.png" alt="top_plant"></img>
            <div className="overlay">
              <div className="text-post-samples">TOP PLANT</div>
            </div>
          </div>

          <div className="brick">
            <img
              src="imgs/volumetricWorld/right_plant.png"
              alt="right_plant"
            ></img>
            <div className="overlay">
              <div className="text-post-samples">RIGHT PLANT</div>
            </div>
          </div>

          <div className="brick">
            <img src="imgs/volumetricWorld/inside_1.png" alt="inside_1"></img>
          </div>

          <div className="brick">
            <img src="imgs/volumetricWorld/front.png" alt="front"></img>
            <div className="overlay">
              <div className="text-post-samples">FRONT</div>
            </div>
          </div>

          <div className="brick">
            <img src="imgs/volumetricWorld/top.png" alt="top"></img>
            <div className="overlay">
              <div className="text-post-samples">TOP</div>
            </div>
          </div>

          <div className="brick">
            <img src="imgs/volumetricWorld/right.png" alt="right"></img>
            <div className="overlay">
              <div className="text-post-samples">RIGHT</div>
            </div>
          </div>

          <div className="brick">
            <img src="imgs/volumetricWorld/inside_2.png" alt="inside_2"></img>
          </div>
        </div>

        <img
          src="imgs/volumetricWorld/real_digital.png"
          alt="real_digital"
          className="volumetricworld-last"
        ></img>
      </div>
    </Layout>
  );
};

export default VolumetricWorld;
