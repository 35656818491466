import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import MouseContextProvider from "./Pages/Components/Context/mouse-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MouseContextProvider>
      <App />
    </MouseContextProvider>
  </React.StrictMode>
);
