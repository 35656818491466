import React from "react";
import { useContext } from "react";
import { MouseContext } from "./Context/mouse-context";

const CharSykes = () => {
  const { cursorChangeHandler } = useContext(MouseContext);

  return (
    <div className="char-wrapper">
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-1.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-2.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-3.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-4.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-5.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-6.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-7.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-8.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-9.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-10.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-11.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-12.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-13.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-14.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/1-15.svg"
        alt=""
        className="char-img"
      ></img>
      {/* //////////////////////////////// 222222 //////////////////////////////// */}
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-1.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-2.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-3.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-4.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-5.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-6.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-7.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-8.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-9.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-10.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-11.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-12.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-13.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-14.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/2-15.svg"
        alt=""
        className="char-img"
      ></img>
      {/* //////////////////////////////// 333333 //////////////////////////////// */}
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-1.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-2.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-3.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-4.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-5.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-6.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-7.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-8.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-9.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-10.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-11.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-12.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-13.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-14.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/3-15.svg"
        alt=""
        className="char-img"
      ></img>
      {/* //////////////////////////////// 444444 //////////////////////////////// */}
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-1.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-2.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-3.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-4.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-5.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-6.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-7.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-8.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-9.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-10.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-11.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-12.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-13.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-14.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/4-15.svg"
        alt=""
        className="char-img"
      ></img>
      {/* //////////////////////////////// 555555 //////////////////////////////// */}
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-1.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-2.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-3.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-4.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-5.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-6.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-7.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-8.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-9.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-10.svg"
        alt=""
        className="char-img"
      ></img>
      <img
        onMouseEnter={() => cursorChangeHandler("hovered-sykes-char")}
        onMouseLeave={() => cursorChangeHandler("")}
        src="imgs/sykes/characteres/5-11.svg"
        alt=""
        className="char-img"
      ></img>
    </div>
  );
};

export default CharSykes;
