import React from "react";
import { Parallax } from "react-parallax";
//import { useContext } from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
//import { MouseContext } from "../Context/mouse-context";
import setBodyColor from "../setBodyColor";

const AltaBaixa = () => {
  const banner = "imgs/alta_baixa.webp";
  //const { cursorChangeHandler } = useContext(MouseContext);
  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="altabaixa">
        <ProjectsIntro
          title="alta & baixa"
          type="Academic"
          teamTitle="Team"
          team="Alexandre Lucas Carlota Silva"
          date="Jan. 2022"
          description="Alta Baixa should present itself as a magazine 
          that sets out to reflect on all aspects of typography, including 
          its history, and whose contents are deliberately eclectic. It aims 
          to explore the structures of organization and presentation of content 
          and to develop in-depth knowledge of the possibilities of the type used 
          as well as composition."
        />
        <div className="altabaixa-project-intro">
          <img src="imgs/altaBaixa/capa.png" alt="capa" className="logos"></img>
        </div>
        <img
          src="imgs/altaBaixa/intro.png"
          alt="intro"
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <Parallax bgImage={banner} strength={100}>
          <div className="parallax"></div>
        </Parallax>

        <img
          src="imgs/altaBaixa/p-1.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-2.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-3.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-4.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-5b.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-6.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>

        <div className="pages-wrapper">
          <div className="single">
            <img
              src="imgs/altaBaixa/tifanny.webp"
              alt=""
              className="pages"
            ></img>
          </div>

          <div className="single">
            <img
              src="imgs/altaBaixa/tornado.webp"
              alt=""
              className="pages"
            ></img>
          </div>
        </div>

        <img
          src="imgs/altaBaixa/p-7.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-8.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-9.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-10.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-11b.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-12.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
        <img
          src="imgs/altaBaixa/p-13.webp"
          alt=""
          style={{ width: "-webkit-fill-available" }}
        ></img>
      </div>
    </Layout>
  );
};

export default AltaBaixa;
