import React from "react";
import { useContext } from "react";
import Layout from "./Components/Layout";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { MouseContext } from "./Components/Context/mouse-context";
import setBodyColor from "./Components/setBodyColor";

const Work = () => {
  const { cursorChangeHandler } = useContext(MouseContext);

  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="work">
        <Splide>
          <SplideSlide>
            <div>
              <a href="/queimadasfitas21">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/queima.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">01</div>
              <div className="slide-title">QUEIMA DAS FITAS '21</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/altabaixa">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/alta_baixa.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">02</div>
              <div className="slide-title">ALTA & BAIXA</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/paulomartins">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/paulo_martins.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">03</div>
              <div className="slide-title">PAULO MARTINS</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/sykes">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/sykes.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">04</div>
              <div className="slide-title">SYKES</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/skillx">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/skillX.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">05</div>
              <div className="slide-title">SKILLX</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/portobc">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/porto_bc.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">06</div>
              <div className="slide-title">PORTO.BC</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/cultureinn">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/culture.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">07</div>
              <div className="slide-title">CULTUREINN</div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div>
              <a href="/volumetricworld">
                <img
                  onMouseEnter={() =>
                    cursorChangeHandler("hovered-projects-images")
                  }
                  onMouseLeave={() => cursorChangeHandler("")}
                  src="./imgs/volumetric_world.webp"
                  alt="project_image"
                  className="slide-image"
                />
              </a>
              <div className="slide-id">08</div>
              <div className="slide-title">VOLUMETRIC WORLD</div>
            </div>
          </SplideSlide>
        </Splide>
        <div className="work-help text-hold">hold and drag</div>
      </div>
    </Layout>
  );
};

export default Work;
