import React, { useContext } from "react";
import setBodyColor from "../../setBodyColor";
import ProjectsIntro from "./../../ProjectsIntro";
import { Parallax } from "react-parallax";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { MouseContext } from "./../../Context/mouse-context";

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(17, 17, 17, 0.9);
  height: 2.5rem;
  font-family: "HelveticaNow-Medium";
  font-size: 0.875rem;
  padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  border-radius: 100rem;
  cursor: pointer;
  transition: ease background-color 250ms;
  border: 0;
  outline: 0;
  backdrop-filter: blur(8px);
  &:hover {
    background-color: #f2f2f2;
    color: #111111;
  }

  &:hover .btn-icon {
    -webkit-animation: spin 900ms ease-in infinite;
    -moz-animation: spin 900ms ease-in infinite;
    animation: spin 900ms ease-in infinite;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const NvAltaBaixa = () => {
  const banner = "imgs/alta_baixa.webp";
  const { cursorChangeHandler } = useContext(MouseContext);

  setBodyColor({ color: "#0d0d0d" });

  return (
    <div>
      <div className="new-header">
        <svg
          className="logo-new logo-new--white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 771.83 126.11"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g>
              <path
                className="cls-1"
                d="M0,41.46h70.12v83.16h-19.47V60.27h-31.19v64.35H0V41.46Z"
              />
              <path
                className="cls-1"
                d="M123.92,125.78c-6.27,0-11.94-1.24-17-3.71-5.06-2.47-9.41-5.75-13.04-9.82-3.63-4.07-6.41-8.63-8.33-13.7-1.93-5.06-2.89-10.23-2.89-15.51s.96-10.48,2.89-15.59c1.92-5.11,4.67-9.71,8.25-13.78,3.57-4.07,7.92-7.37,13.04-9.9,5.11-2.53,10.81-3.79,17.08-3.79s12.1,1.27,17.16,3.79c5.06,2.53,9.38,5.83,12.95,9.9,3.57,4.07,6.32,8.66,8.25,13.78,1.92,5.12,2.89,10.31,2.89,15.59s-.96,10.45-2.89,15.51c-1.93,5.06-4.68,9.63-8.25,13.7-3.58,4.07-7.92,7.34-13.04,9.82-5.11,2.48-10.81,3.71-17.08,3.71ZM123.92,107.46c3.3,0,6.27-.71,8.91-2.14,2.64-1.43,4.89-3.3,6.77-5.61,1.87-2.31,3.3-4.92,4.29-7.84.99-2.91,1.48-5.86,1.48-8.83s-.5-5.91-1.48-8.83c-.99-2.91-2.42-5.55-4.29-7.92-1.87-2.36-4.12-4.26-6.77-5.69-2.64-1.43-5.61-2.15-8.91-2.15s-6.27.72-8.91,2.15c-2.64,1.43-4.87,3.33-6.68,5.69-1.81,2.37-3.22,5.01-4.21,7.92-.99,2.92-1.48,5.86-1.48,8.83s.5,5.91,1.48,8.83c.99,2.92,2.39,5.53,4.21,7.84s4.04,4.18,6.68,5.61c2.64,1.43,5.61,2.14,8.91,2.14Z"
              />
              <path
                className="cls-1"
                d="M182.16,60.44h-13.53v-19.14h13.53V1.86h19.47v39.43h13.69v19.14h-13.69v45.21h23.76v18.97h-43.23V60.44Z"
              />
              <path
                className="cls-1"
                d="M281.32,60.6h-13.7v-18.98h13.7V1.86h65.5v18.81h-46.2v20.96h28.22v18.98h-28.22v64.02h-19.3V60.6Z"
              />
              <path
                className="cls-1"
                d="M373.06,125.78c-6.27,0-11.94-1.24-17-3.71-5.06-2.47-9.41-5.75-13.04-9.82-3.63-4.07-6.41-8.63-8.33-13.7-1.93-5.06-2.89-10.23-2.89-15.51s.96-10.48,2.89-15.59c1.92-5.11,4.67-9.71,8.25-13.78,3.57-4.07,7.92-7.37,13.04-9.9,5.11-2.53,10.81-3.79,17.08-3.79s12.1,1.27,17.16,3.79c5.06,2.53,9.38,5.83,12.95,9.9,3.57,4.07,6.32,8.66,8.25,13.78,1.92,5.12,2.89,10.31,2.89,15.59s-.96,10.45-2.89,15.51c-1.93,5.06-4.68,9.63-8.25,13.7-3.58,4.07-7.92,7.34-13.04,9.82-5.11,2.48-10.81,3.71-17.08,3.71ZM373.06,107.46c3.3,0,6.27-.71,8.91-2.14,2.64-1.43,4.89-3.3,6.76-5.61,1.87-2.31,3.3-4.92,4.29-7.84.99-2.91,1.49-5.86,1.49-8.83s-.5-5.91-1.49-8.83c-.99-2.91-2.42-5.55-4.29-7.92-1.87-2.36-4.12-4.26-6.76-5.69-2.64-1.43-5.61-2.15-8.91-2.15s-6.27.72-8.91,2.15c-2.64,1.43-4.87,3.33-6.68,5.69-1.81,2.37-3.22,5.01-4.21,7.92-.99,2.92-1.48,5.86-1.48,8.83s.5,5.91,1.48,8.83c.99,2.92,2.39,5.53,4.21,7.84s4.04,4.18,6.68,5.61c2.64,1.43,5.61,2.14,8.91,2.14Z"
              />
              <path
                className="cls-1"
                d="M426.85,41.46h19.47v64.19h31.19V41.46h19.47v83.16h-70.12V41.46Z"
              />
              <path
                className="cls-1"
                d="M511.99,41.46h70.12v83.16h-19.47V60.27h-31.19v64.35h-19.47V41.46Z"
              />
              <path
                className="cls-1"
                d="M659.83,118.85c-3.52,2.42-7.37,4.24-11.55,5.45-4.18,1.21-8.47,1.82-12.87,1.82-6.6,0-12.43-1.24-17.49-3.71-5.06-2.47-9.32-5.72-12.79-9.74-3.47-4.01-6.08-8.58-7.84-13.69-1.76-5.12-2.64-10.37-2.64-15.76s.88-10.67,2.64-15.84c1.76-5.17,4.37-9.76,7.84-13.78,3.46-4.01,7.73-7.26,12.79-9.74,5.06-2.47,10.89-3.71,17.49-3.71,4.4,0,8.66.63,12.79,1.9,4.12,1.27,8,3.05,11.63,5.36v-21.45c0-2.53-.03-4.4-.08-5.61-.06-1.21-.08-2.14-.08-2.8,0-1.43.05-2.97.17-4.62l.5-.99.33-.99c.22-.44.47-.93.74-1.49.27-.55.58-1.1.91-1.65.66-1.1,1.24-2.12,1.73-3.05.5-.93.8-1.51.91-1.73l.66-.99h20.46v18.64h-6.76v103.95h-19.47v-5.78ZM635.41,107.63c3.3,0,6.38-.71,9.24-2.14,2.86-1.43,5.33-3.3,7.43-5.61,2.09-2.31,3.71-4.92,4.87-7.84,1.16-2.91,1.73-5.86,1.73-8.83s-.58-5.94-1.73-8.91-2.78-5.61-4.87-7.92c-2.09-2.31-4.57-4.18-7.43-5.61-2.86-1.43-5.94-2.14-9.24-2.14s-6.3.72-8.99,2.14c-2.7,1.43-5.01,3.3-6.93,5.61-1.93,2.31-3.38,4.95-4.37,7.92-.99,2.97-1.49,5.94-1.49,8.91s.5,5.91,1.49,8.83c.99,2.92,2.45,5.53,4.37,7.84,1.92,2.31,4.23,4.18,6.93,5.61,2.69,1.43,5.69,2.14,8.99,2.14Z"
              />
              <path
                className="cls-1"
                d="M694.15,105.65h19.47v18.97h-19.47v-18.97Z"
              />
              <path
                className="cls-1"
                d="M748.48,0c6.57,0,12.12,2.2,16.65,6.6,4.47,4.4,6.7,9.76,6.7,16.08s-2.23,11.68-6.7,16.08c-4.53,4.4-10.08,6.6-16.65,6.6s-12.12-2.2-16.65-6.6-6.79-9.76-6.79-16.08,2.26-11.68,6.79-16.08,10.08-6.6,16.65-6.6ZM761.69,35.6c3.57-3.57,5.36-7.88,5.36-12.92s-1.79-9.44-5.36-13.02c-3.51-3.51-7.91-5.26-13.21-5.26s-9.79,1.75-13.3,5.26c-3.57,3.57-5.36,7.91-5.36,13.02s1.79,9.35,5.36,12.92c3.51,3.51,7.94,5.26,13.3,5.26s9.7-1.75,13.21-5.26ZM757.86,30.82c.06,1.4.45,2.65,1.15,3.73h-4.31c-.57-.77-.89-2.01-.96-3.73l-.19-2.49c-.13-1.34-.43-2.27-.91-2.78s-1.29-.77-2.44-.77h-6.6v9.76h-4.21V10.24h10.34c2.68,0,4.77.59,6.27,1.77s2.25,2.85,2.25,5.02c0,2.81-1.18,4.72-3.54,5.74,1.72.64,2.68,2.14,2.87,4.5l.29,3.54ZM750.11,20.96c2.55,0,3.83-1.15,3.83-3.45s-1.4-3.45-4.21-3.45h-6.12v6.89h6.51Z"
              />
            </g>
          </g>
        </svg>

        <div className="new-header__actions">
          <NavLink
            onMouseEnter={() => cursorChangeHandler("hovered-social")}
            onMouseLeave={() => cursorChangeHandler("")}
            exact="true"
            to="/2024"
            className="secondary-btn"
          >
            <span>Selected works</span>
          </NavLink>

          <Button
            onMouseEnter={() => cursorChangeHandler("hovered-social")}
            onMouseLeave={() => cursorChangeHandler("")}
            className="primary-btn"
            href="https://drive.google.com/file/d/1lgWRQAFR6NoLQPo5YchKrfIXBTW0OcBW/view?usp=sharing"
            rel="noreferrer"
            target="_blank"
          >
            Resume
            <span className="material-icons btn-icon">arrow_outward</span>
          </Button>
        </div>
      </div>
      <div className="mobile-content">
        <div className="loader" style={{ color: "#f8f8f8" }}>
          Mobile magic is coming soon! <br />
          For now, grab your laptop or desktop for the full feast. <br />
          <br />
          Thanks for your patience!
        </div>
      </div>
      <div className="project-page">
        <div className="nv-altabaixa">
          <ProjectsIntro
            title="alta & baixa"
            type="Academic"
            teamTitle="Team"
            team="Alexandre Lucas Carlota Silva"
            date="Jan. 2022"
            description="Alta Baixa should present itself as a magazine 
          that sets out to reflect on all aspects of typography, including 
          its history, and whose contents are deliberately eclectic. It aims 
          to explore the structures of organization and presentation of content 
          and to develop in-depth knowledge of the possibilities of the type used 
          as well as composition."
          />
          <div className="altabaixa-project-intro">
            <img
              src="imgs/altaBaixa/capa.png"
              alt="capa"
              className="logos"
            ></img>
          </div>
          <img
            src="imgs/altaBaixa/intro.png"
            alt="intro"
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <Parallax bgImage={banner} strength={100}>
            <div className="parallax"></div>
          </Parallax>

          <img
            src="imgs/altaBaixa/p-1.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-2.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-3.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-4.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-5b.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-6.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>

          <div className="pages-wrapper">
            <div className="single">
              <img
                src="imgs/altaBaixa/tifanny.webp"
                alt=""
                className="pages"
              ></img>
            </div>

            <div className="single">
              <img
                src="imgs/altaBaixa/tornado.webp"
                alt=""
                className="pages"
              ></img>
            </div>
          </div>

          <img
            src="imgs/altaBaixa/p-7.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-8.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-9.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-10.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-11b.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-12.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
          <img
            src="imgs/altaBaixa/p-13.webp"
            alt=""
            style={{ width: "-webkit-fill-available" }}
          ></img>
        </div>
        <div className="new-footer">© 2024, NOT FOUND.</div>
      </div>
    </div>
  );
};

export default NvAltaBaixa;
