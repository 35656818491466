import React from "react";
import { useContext } from "react";
import Layout from "../Layout";
import ProjectsIntro from "../ProjectsIntro";
import { MouseContext } from "../Context/mouse-context";
import setBodyColor from "../setBodyColor";

const PortoBC = () => {
  const { cursorChangeHandler } = useContext(MouseContext);

  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="portobc">
        <ProjectsIntro
          title="porto.bc"
          type="Junior Company"
          teamTitle="Team"
          team="jeKnowledge"
          date="Feb. 2021"
          description="Porto.BC is a event dedicated to blockchain 
          and cryptocurrencies to be broadcasted from Portugal to 
          any place in the world in 2021. The main goal is to create 
          as image that stands out for its ability to connect with people. 
          This minimalist image, moves away from the common in this area. 
          The elements of the logo come together naturally, involving us 
          in their flow of lines."
        />
        <ul className="portobc-list">
          <li className="portobc-list-element">
            <a
              onMouseEnter={() => cursorChangeHandler("hovered-see")}
              onMouseLeave={() => cursorChangeHandler("")}
              href="https://www.behance.net/gallery/112153635/PortoBC-Brand-Identity-Design"
              className="portobc-list-text text-see"
              rel="noopener noreferrer"
              target="_blank"
            >
              SEE ON BEHANCE
            </a>
          </li>
        </ul>

        <section>
          <div className="scroll text-1">
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
            <div>&nbsp;IS NOT HERE YET&nbsp;</div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PortoBC;
