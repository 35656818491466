import React from "react";
import Layout from "./Components/Layout";
import MasonrySamples from "./Components/MasonrySamples";
import setBodyColor from "./Components/setBodyColor";

const Samples = () => {
  setBodyColor({ color: "#0d0d0d" });

  return (
    <Layout>
      <div className="samples">
        <MasonrySamples />
      </div>
    </Layout>
  );
};

export default Samples;
